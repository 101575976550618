import React from "react"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import getYouTubeId from "get-youtube-id"
import { AiFillYoutube } from "react-icons/ai"
import { MdClose } from "react-icons/md"

import YouTube from "react-youtube"

const CaseContainer = ({ data }) => {
  log(data.sanityPages.mainImage)
  if (data.sanityPages.mainImage.video != undefined) {
    return (
      <div className="page_opening">
        <div className="page_opening_image" onClick={openVideoContainer}>
          <Img
            fluid={data.sanityPages.mainImage.image.asset.fluid}
            alt={data.sanityPages.mainImage.alt}
            // onLoad={imageLoaded}
          />
          <AiFillYoutube />
          <div className="iconFillAiFillYoutube"></div>
        </div>

        <div
          className="page_opening_video"
          onClick={openVideoContainerByClickingOnIframe}
        >
          <MdClose
            className="closeIframeContainer"
            onClick={closeIframeContainer}
          />
          {getIframeContent(data.sanityPages.mainImage.video)}
        </div>
        <div className="opening_desc">
          <h1>{data.sanityPages.title}</h1>
          <BlockContent
            blocks={data.sanityPages._rawSubtitle}
            projectId="ppd47c0u"
            dataset="production"
            serializers={serializers}
            className="newBlockType"
          />{" "}
        </div>
      </div>
    )
  } else {
    return (
      <div className="page_opening standard_page_opening">
        <Img
          fluid={data.sanityPages.mainImage.image.asset.fluid}
          alt={data.sanityPages.mainImage.alt}
          className="page_opening_image_order_setting"
        />

        <div className="opening_desc">
          <h1>{data.sanityPages.title}</h1>
          <BlockContent
            blocks={data.sanityPages._rawSubtitle}
            projectId="ppd47c0u"
            dataset="production"
            serializers={serializers}
            className="newBlockType"
          />{" "}
        </div>
      </div>
    )
  }
}

export default CaseContainer

function getIframeContent(url) {
  // const { url } = node
  const id = getYouTubeId(url)
  return <YouTube videoId={id} />
}
function log(log) {
  console.log(log)
}

function openVideoContainer(e) {
  e.target.parentNode.parentNode
    .querySelector(".page_opening_video")
    .classList.add("showIframeContainer")
}

function openVideoContainerByClickingOnIframe(e) {
  console.log(e.target.classList)
  e.target.classList.remove("showIframeContainer")
}
function closeIframeContainer(e) {
  e.target.parentNode.classList.remove("showIframeContainer")
}
