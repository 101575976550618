import React from "react"

import { MdArrowDropDown } from "react-icons/md"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
// import { func } from "prop-types"

const CaseContainer = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allSanityToolCategory {
        edges {
          node {
            title
            id
          }
        }
      }
      allSanityTools(sort: { fields: title, order: ASC }) {
        edges {
          node {
            title
            id
            url
            toolCategory {
              id
            }
            image {
              asset {
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className="tool_container animation--fade-up animation ">
      <div className="option_wrapper mobile_options">
        <span className="arrow">
          <MdArrowDropDown />
        </span>
        <select
          onChange={optionClicked}
          id="toolCategories"
          name="toolCategories"
        >
          <option value="all">Alle</option>

          {data.allSanityToolCategory.edges.map(function(edge) {
            return <option value={edge.node.id}>{edge.node.title}</option>
          })}
          <span>
            <MdArrowDropDown />
          </span>
        </select>
      </div>

      <div className="option_wrapper desktop_options">
        <button onClick={tabClicked} id="all" className="tab tab_clicked">
          Alle
        </button>
        {data.allSanityToolCategory.edges.map(function(edge) {
          return (
            <button onClick={tabClicked} id={edge.node.id} className="tab">
              {edge.node.title}
            </button>
          )
        })}
      </div>

      <div className="tools">
        {data.allSanityTools.edges.map(function(tool) {
          return (
            <div
              id={tool.node.toolCategory.id}
              className="tool_wrapper display_tool"
            >
              <a href={tool.node.url} target="_blank" rel="noreferrer">
                <Img
                  fluid={tool.node.image.asset.fluid}
                  alt={tool.node.title}
                  title={tool.node.title}
                />
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default CaseContainer

function optionClicked() {
  let id = document.getElementById("toolCategories")
  let strUser = id.options[id.selectedIndex].value

  const allCases = document.querySelectorAll(".tool_wrapper")

  if (strUser !== "all") {
    allCases.forEach(caseElement => {
      if (caseElement.id === strUser) {
        caseElement.classList.add("display_tool")
      }
      if (caseElement.id !== strUser) {
        caseElement.classList.remove("display_tool")
      }
    })
  } else {
    allCases.forEach(caseElement => {
      caseElement.classList.add("display_tool")
    })
  }
}

function tabClicked(e) {
  let strUser = e.target.id

  const allCases = document.querySelectorAll(".tool_wrapper")
  const allTabs = document.querySelectorAll(".tab")

  allTabs.forEach(tab => {
    tab.classList.remove("tab_clicked")
  })

  e.target.classList.add("tab_clicked")

  allCases.forEach(caseElement => {
    caseElement.classList.remove("display_tool")
  })

  if (strUser !== "all") {
    allCases.forEach(function(caseElement, index) {
      if (caseElement.id === strUser) {
        caseElement.classList.add("display_tool")
      }
      if (caseElement.id !== strUser) {
        caseElement.classList.remove("display_tool")
      }
    })
  } else {
    allCases.forEach(caseElement => {
      caseElement.classList.add("display_tool")
    })
  }
  resizeTools()
}

function resizeTools() {
  let numberOfDisplayedTools = document.querySelectorAll(".display_tool").length

  if (numberOfDisplayedTools < 5) {
    document.querySelector(".tools").classList.add("lessThanFive")
  }
  if (numberOfDisplayedTools > 5) {
    document.querySelector(".tools").classList.remove("lessThanFive")
  }
}
