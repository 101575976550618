import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tools from "../components/tools"
import ContactForm from "../components/contactForm"
import PageOpening from "../components/pageOpening"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "8e21845b-bff4-5a83-9ee7-deafa6f3cd02" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          video
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityAccounting(sort: { fields: publishedAt, order: ASC }) {
        edges {
          node {
            title
            subtitle
            slug {
              current
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <div className="sub_header">
        {data.allSanityAccounting.edges.map(function(accounting) {
          return (
            <Link
              activeClassName="active"
              to={`/${accounting.node.slug.current}/`}
            >
              {accounting.node.title}
            </Link>
          )
        })}
      </div>
      <section className="index services">
        <PageOpening data={data} />

        <BlockContent
          className={data.sanityPages.templateList}
          blocks={data.sanityPages._rawBody}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />

        <h2 className="tool_header animation--fade-up animation">
          Vi arbejder og har kendskab til mange forskellige systemer, <br></br>
          det sikrer jer den bedste løsning
        </h2>

        <Tools></Tools>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default IndexPage
